.FiltersLine {
    display: flex;
}

.MobileFiltersLine {
    margin-bottom: 1.5rem;

    .FiltersLine {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: end;

        .FilterField {
            margin-left: 0;
            margin-right: 1rem;
            width: 100%;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .MuiInputBase-sizeSmall {
        .MuiChip-root {
            padding: 0;
        }

        .MuiChip-deleteIcon {
            width: 14px;
            height: 14px;
        }
    }
}
