.NotificationFormSubmit {
    .FormValues {
        background: #fff;
        padding: 1em;
        border-radius: 5px;
        margin-top: .5rem;
        margin-bottom: .5rem;

        .labelWrap  {
            padding-left: 0;
            margin-bottom: 0;

            .label {
                line-height: 1.2;
                margin-bottom: 5px;
                text-wrap: auto;
            }
        }

        @media (max-width: 599px) {
            .labelWrap {
                display: block;
                margin-bottom: .5rem;
            }
        }
    }
}
