.FormValues {
    .photo {
        width: 20px;
        border-radius: 2px;
    }

    .gallery {
        .photo {
            margin-right: .5rem;

            width: 60px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .gallery-photo {
        width: 60px;
    }
}
