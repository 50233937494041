.HistoryValidateOrderPage {
    .InlineActions {
        width: auto;
        position: absolute;
        right: -1rem;
        top: 0;

        .ContentCopyIconWrap {
            background: #fffffff2;
            border: 1px solid #ccc;
            border-radius: 50%;
            height: 26px;
            line-height: 26px;
            padding: .5rem;
            width: 26px;

            .MuiSvgIcon-root {
                background: none;
                border: 0;
                border-radius: 0;
                padding: 0;
            }
        }
    }

    .labelWrap {
        padding-right: 1.5rem;
    }

    .labelWrapRow {
        .FormValues {
            border: 1px solid #ddd;
            border-radius: 15px;
            padding: 1rem;
            margin-top: 1rem;

            .label {
                white-space: break-spaces;
            }

            .labelWrap {
                padding-left: .5rem;

                @media (max-width: 600px) {
                    display: block;
                }
            }
        }
    }

    .StatusUpdate {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin: 1rem;
        margin-top: 2rem;

        .status {
            padding: 0 1rem;
        }

        .statusWrap {
            display: flex;
            gap: 1em;

            @media (max-width: 599px) {
                flex-direction: column;
            }
        }
    }
}
