.GalleryLine {
    .swiper {
        &.swiper-autoheight {
            margin: 0 -4px;
        }

        .swiper-slide {
            padding: 0 4px;
        }

        .swiper-button-prev {
            margin-left: 0;
        }

        .swiper-button-next {
            margin-right: 0;
        }

        .swiper-button-prev,
        .swiper-button-next {
            width: 30px;
            height: 30px;
            margin-top: -18px;

            &:after {
                font-size: 16px;
            }
        }
    }
}
