.TableWrap {
    position: relative;
    // margin-bottom: 2rem;

    .TableAutoWrap {
        overflow: auto;
    }

    .detailsTD,
    .td_conversationOpen {
        width: 30px!important;
        text-align: right;
        padding-top: 0.5rem!important;
        padding-bottom: .5rem!important;
        font-size: 0;
        vertical-align: middle;
    }

    .detailsTD svg,
    .moreBtn {
        cursor: pointer;
        border: 1px solid #ccc;
        background: #fff;
        border-radius: 50%;
        height: 26px;
        width: 26px;
        line-height: 26px;
        padding: 0.5rem;
    }

    .ActionsCell {
        width: 56px;
        font-size: 0;
        text-align: center;
        padding-top: .5rem!important;
        padding-bottom: .5rem!important;
    }
    
    .DetailsBtn {
        width: 30px;
    }

    .noContent {
        background: none!important;
        padding: 3rem 1rem 6rem!important;
        opacity: .5;
    }

    .Details {
        padding-top: 0!important;
        padding-left: 1rem!important;
        padding-right: 1rem!important;
        padding-bottom: 1rem!important;
        vertical-align: top;

        p {
            margin-top: 0;
            padding-right: .5rem;
        }

        .labelWrap {
            @media (min-width: 600px) {
                padding-left: 0;
            }

            @media (max-width: 559px) {
                display: block;

                &:first-child {
                    margin-top: 1rem;
                }
            }
        }

        .CKEditorContent {
            padding-left: 0;
            margin-bottom: 0;
        }

        .labelWrap.status {
            .status {
                padding-bottom: 1rem;
            }

            @media (min-width: 600px) {
                .labelValue {
                    gap: 10px;
                    flex-direction: column;
                    align-items: start;
                }

                .statusWrap {
                    display: flex;
                    gap: 10px;
                }

                .color_red {
                    color: #c00!important;
                }
            }
        }

        .labelWrap.category {
            .labelValue .MuiChip-root {
                margin-left: .5rem!important;
            }

            .labelValue span:first-child .MuiChip-root {
                margin-left: 0!important;
            }
        }

        .labelWrap.gallery {
            display: block;

            .label {
                margin-bottom: .5rem;
                display: block;
            }
        }

        .ImagesList {
            .image {
                width: 64px;
            }
        }
    }

    .DetailsBlockWrap {
        background: #fff;
        border-radius: 15px;
        margin: 1rem 0 0 0;
        padding: 1rem;
        width: 50%;

        .FormID {
            font-style: italic;
            font-size: 12px;
            opacity: 0.6;
        }

        @media (max-width: 599px) {
            display: block;
            width: 100%;
            box-sizing: border-box;
        }
    }

    table.Table {
        border-collapse:collapse;

        width: 100%;
        border-radius: 15px;

        & + .Pagination {
            margin-top: 2rem;
        }

        th {
            text-align: left;
            padding: .5rem;
            line-height: 1.2rem;
            white-space: nowrap;
        }
        
        tr {
            &.__new_attached {
                opacity: 0.5;
            }

            &.details_hidden,
            &.item {
                // td {
                //     cursor: pointer;
                // }

                td:first-child {
                    border-top-left-radius: 15px;
                }
                td:last-child {
                    border-top-right-radius: 15px;
                }
            }

            &.no_details,
            &.details_hidden,
            &.details {
                td:first-child {
                    border-bottom-left-radius: 15px;
                }
                td:last-child {
                    border-bottom-right-radius: 15px;
                }
            }

            &.has_details,
            &.has_actions {
                td {
                    padding-top: 1.2rem;
                    padding-bottom: 1.2rem;
                }
            }
            
            td {
                background-color: #f6f6f6;
            }
        }

        tr.item {
            td {
                border-top: 5px solid #fff;
                vertical-align: middle;
            }
        }
        
        td {
            padding: .5rem;
            // max-width: 200px;

            // @media (min-width: 600px) {
                vertical-align: top;
            // }

            @media (max-width: 599px) {
                width: 100%;
            }

            .tableImages {
                border-radius: 15px;
                height: auto;
                max-width: 60px;

                margin-top: -.5rem;
                margin-bottom: -.5rem;

                @media (min-width: 600px) {
                    max-width: 60px;
                }
            }

            &:first-child {
                padding-left: 1rem;
            }

            &:last-child {
                padding-right: 1rem;
            }
        }

        tr.details {
            td {
                display: none;
            }

            &.visible {
                td {
                    display: table-cell;
                }
            }
        }
        
        .additionalPopap {
            color: #999;
        
            &:hover {
                color: #333;
            }
        }

        .actions {
            td {
                padding: 0;
            }
        }
    }

    .InfinityPreloader {
        position: relative;
        height: 120px;
    }

    .loadMoreButton {
        margin-top: 1rem;
    }

    &.topAlign {
        tr.item td {
            vertical-align: top;
        }
    }
}

.TableWrap table.Table {
    .th__created_by {
        padding-right: 2rem;
    }

    .th_timestamp,
    .td_timestamp {
        width: 130px;
        min-width: 130px;
    }

    .td_city {
        .cityLabel {
            margin: -6px 0;
        }
    }

    .th_city,
    .td_city {
        width: 90px;   
    }

    .th_region,
    .td_region {
    //     width: 140px;
    //     min-width: 140px;
        padding-right: 2rem;
    }

    .th_thumbnail,
    .td_thumbnail,
    .th_node_image,
    .td_node_image {
        width: 70px;
        min-width: 70px;
    }

    .th_access,
    .td_access {
        width: 200px;
        min-width: 200px;
    }

    .td__node_image,
    .td_image {
        font-size: 0;
    }

    .th_node,
    .td_node {
        padding-right: 2rem;
    }
}
