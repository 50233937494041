.Recomendation {
    overflow: hidden;

    .RecommendationCard {
        //.pseudoButton,
        .MuiButtonBase-root{
            margin-bottom: 1rem;
        }

        @media (min-width: 600px) {
            margin-bottom: 0;
            width: 120px;
            float: left;
            // margin-right: 2rem;
        }

        @media (max-width: 599px) {
            float: left;
            width: 90px;
            margin-right: 1rem;

            // .thumbnail {
            //     margin-bottom: 2rem;
            // }
        }
    }

    .categories {
        .MuiChip-root {
            margin-right: .5rem;
        }
    }

    .tags {
        margin-top: 2rem;
    }

    .LocationWidget {
        clear: both;

        @media (min-width: 600px) {
            padding-top: 1rem;
        }

        @media (max-width: 599px) {
            .labelWrap {
                display: block;
            }
        }
    }

    .RecomendationDescription,
    .categories {
        margin-bottom: 2rem;

        @media (min-width: 600px) {
            margin-left: 154px;

            .CKEditorContent  {
                padding-left: 0;
            }
        }
    
        .labelWrap {
            padding-left: 0;
        }

        p {
            margin-top: 0;
        }
    }

    .Carousel {
        margin-top: 2rem;

        @media (min-width: 600px) {
            margin-top: 3rem;
        }
    }

    // .CKEditorContent {
    //     margin-top: 3rem;
    // }
}
