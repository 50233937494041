.Location {
    // .howToGetCoordinates,
    // .fieldDetails {
    //     font-size: .9rem;
    // }

    &.error {
        color: rgb(211, 47, 47);
        border-color: rgb(211, 47, 47);

        .FormFieldsGroup {
            border-color: rgb(211, 47, 47);
        }
    }

    &.extended {
        // .Map {
            // .Search {
            //     text-align: center;
            //     position: relative;
            //     left: 0;
            //     right: 0;
            //     top: 0;
            //     z-index: 1;
            //     width: 100%;
            //     margin-bottom: 1rem;
            // }
        // }
    }

    .pseudoLink {
        font-size: .9rem;
    }

    .showHowToGetCoordinates {
        font-size: .9rem;
        color: #555;
    }

    .FormFieldsGroup {
        padding-top: 1rem;

        .howToGetCoordinatesWrap {
            padding-top: 0;
            margin-top: 0;
        }

        .howToGetCoordinates {
            margin: 0;
            margin-top: -1rem;
        }

        .ClearBtn {
            text-align: center;
            min-height: 59px;

            &:disabled {
                opacity: .5;
            }

            .MuiButton-icon {
                margin-right: 0;
                margin-left: 0;

                .MuiSvgIcon-root {
                    font-size: 24px;
                }
            }
        }
    }

    // .error .FormFieldsGroup {

    // }

    .Map {
        position: relative;
        border-radius: 15px;

        .Search {
            position: absolute;
            left: .5rem;
            right: .5rem;
            top: .5rem;
            z-index: 1;
            width: calc(100% - 1rem);
        }
    }

    .GeoLocationButtons {
        display: flex;
        gap: 1rem;
        flex-direction: column;
    }

    .locationDetails {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .labelWrap {
        margin-bottom: 0;
    }

    .labelValue {
        display: flex;
        align-items: center;
    }

    .Link {
        word-break: break-word;
    }

    .editTitle {
        position: relative;
        font-size: 0;
        margin-left: .5rem;
        align-items: center;

        .MuiSvgIcon-root {
            background: #fff;

            border-radius: 50%;
            border: 1px solid #ccc;

            margin: 0!important;
            padding: .2rem;
            width: 16px;
            height: 16px;

            cursor: pointer;
        }
    }
}

.MuiAutocomplete-popper {
    .LocationSearchResultItem {
        .title {

        }

        .details {
            opacity: .8;
            font-size: 14px;
        }
    }
}

.locationFieldOption {
    display: flex;
    align-items: center;
    gap: .5rem;
    overflow: hidden;
}
