.LogoUploader {
  text-transform: none;

  .MuiFormControl-root {
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }

  .pseudoButton {
    display: block;
    text-transform: none;
    padding: .5rem 1rem;

    .select {
      display: flex;
      align-items: center;
      gap: 1rem;

      @media (max-width: 599px) {
        display: block;
      }
    }
  }

  .photoWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.5rem 0 0;

    .photoWrapInner {
      position: relative;
    }

    .photo {
      max-width: 100%;
      border-radius: 15px;
    }

    .close {
      cursor: pointer;
      display: block;
      position: absolute;
      right: -7px;
      top: -14px;

      font-size: 0;

      padding: 2px;

      background: #303030;
      color: #fff;
      border-radius: 50%;

      &:hover {
        opacity: 0.9;
      }
    }
  }

  .inputs {
    padding-top: .5rem;

    .wrapper {
      // margin-bottom: 1rem;
      display: flex;
      align-items: center;

      .MuiButton-root {
        display: inline-flex !important;
        padding: 0 !important;
        margin-left: 6px;
        min-height: 58px;
        height: 58px;
        width: 68px;
        box-shadow: none !important;

      }
    }
  }

  .Mui-focused {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin: 0!important;
  }

  .MuiFormLabel-root {
    opacity: .6;
  }

  .timer {
    animation: blinker 2s linear infinite;
    color: #d00;
  }
}

@keyframes blinker {
    50% {
      opacity: 0;
    }
}