.CellMessage,
.CellConversation {
    // .content {
    //     margin-bottom: .5rem;
    // }

    .timestamp {
        opacity: .5;
        font-weight: normal;
        margin: 0;
        // margin-top: -.5rem;
    }

    .CellConversationTitle {
        font-weight: bold;
        margin-bottom: .2rem;
        font-size: 1.2rem;
        margin-top: -.3rem;
        // a {
        //     margin-right: 1rem;
        // }
    }

    .text {
        position: relative;
    }
}

.CellMessage {
    // overflow: hidden;
    display: flex;
    align-items: start;

    @media (min-width: 600px) {
        align-items: center;
    }

    .avatar {
        margin-right: 1rem;
    }

    .text {
        width: calc(100vw - 162px);

        @media (min-width: 600px) {
            width: 100%;
        }
    }

    &.sender,
    &.privateConversation {
        .avatar {
            display: none;
        }

        .text {
            width: 100%;
        }
    }
}

// .ConversationBadge {
//     .MuiBadge-badge {
//         right: 10px;
//         top: 10px;
//     }
// }

.tr_unseen {
    .CellConversation .NotificationTitle {
        font-weight: normal!important;
    }

    .CellMessage .NotificationTitle {
        font-weight: bold!important;
    }
}

.td_conversation-logo {
    padding-right: .2rem;
}

.td_transfer-order,
.td_exchange-order {
    white-space: nowrap;
}

.td_status .status {
    background: #fff;
    text-align: center;
    padding: .5rem;
    border-radius: 5px;
    margin-bottom: .5rem;
}

.td_exchange-order {
    padding-right: 2rem;
}

.FilterClickableValue {
    text-transform: lowercase;
}

.ExchangeMobileOrder {
    .order {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    .user {
        margin-bottom: .5rem;
    }

    .oid {
        position: relative;
        color: #555;
        font-size: 1.5rem;
        font-weight: 300;
        // font-size: 12px;

        .MuiSvgIcon-root {
            position: absolute;
            right: 0;
            top: 5px;
            font-size: 1rem;
        }
    }

    .time {
        font-size: 13px;
        margin-bottom: .5rem;
    }

    .details {
        background: #fff;
        border-radius: 15px;
        margin: .5rem 0;
        padding: 1rem;

        .FormID {
            font-style: italic;
            font-size: 12px;
            opacity: 0.6;
        }

        pre {
            margin-bottom: 0;
        }
    }

    .status {
        padding: .5rem 1rem;
        border-radius: 15px;
        background: #fff;
        color: #111;
        margin-bottom: 1rem;
    }

    .statusWrap {
        display: flex;
        gap: .5rem;
        flex-direction: column;
    }

    .statusError {
        color: #c00;
    }

    .action {
        @media (max-width: 599px) {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
    }

    .color_red {
        color: #c00!important;
    }
}

.ExchangeMobileOrderDetailsPopup {
    position: relative;
    border: 1px solid #ccc;
    opacity: .7;
    padding: 1rem;
    border-radius: 15px;
    margin-bottom: 1rem;

    .CopyIcon {
        cursor: pointer;
        position: absolute;
        right: .5rem;
        top: .5rem;
        background: #fffffff2;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: .5rem;
        z-index: 1;
        font-size: 0;
    }

    pre,
    .pre {
        max-height: 184px;
        overflow: auto;
        margin: 0;
        margin-right: -1rem;
        padding-right: 1rem;
    }
}


.ExchangeMobileOrderDetailsActions {
    display: flex;
    gap: 10px;
    flex-direction: column;
}
