.AppWrap {
  background: #fff;
  width: 100%;

  border-radius: 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  @media (min-width: 600px) {
    width: calc(900px + 4rem);

    border-radius: 15px;

    margin-left: auto;
    margin-right: auto;
  }
}

.Content {
  position: relative;

  padding: 1rem;

  word-wrap: break-word;

  transition: min-height .5s ease-out;

  .OnMaintenance {
    text-align: center;
    padding: 200px 0 0;
    font-style: italic;
    font-size: 1.2rem;
    color: #aaa;
    font-weight: 200;
    letter-spacing: 3px;

    @media (min-width: 600px) {
      padding: 100px 0;
      font-size: 1.5rem;
    }
  }

  @media (min-width: 600px) {
    padding-bottom: 1rem;
  }
}

.Content {
  min-height: 180px;

  &.Preloading {
    min-height: 280px;    
  }
}

body.mobile {
  .Content {
    min-height: calc(100vh - 200px);
  }
}

html.search {
  // prevent scroll
  overflow: hidden;
}

.pageTop {
  min-height: 42px;
  transition: min-height .2s ease-in;
}

.BannerCarousel.MainBannerCarousell,
.BannerCarousel.MainBannerCarousell .Banner .BannerImgWrap,
.BannerCarousel.MainBannerCarousell .BannerPreloader {
  height: 130px!important;

  @media (min-width: 600px) {
    height: 200px!important;
  }
}

.pageTopTitle {
  padding-bottom: 1rem;
}

.NodeMainTitle + .Breadcrumbs {
  padding-left: 1.5rem;
  margin-bottom: 1.5rem!important;
  margin-top: -1rem;
}

.Breadcrumbs + .SelectedFilters {
  margin-bottom: 1.5rem!important;
}

.main .NodeMainTitle + .Breadcrumbs {
  padding-left: 0;
}

@media (max-width: 599px) {
  body.show_calendar {
    .pageTop {
      min-height: 400px;
    }

    .CalendarWidget {
      height: 400px;
    }
  }
}

#page_did {
  display: none;
}

body.loading:not(.error_page) {
  background: none!important;

  .pageTop {
    background: none;
  }
}

body.telegram {
  // https://dev.to/nimaxin/how-to-fix-the-telegram-mini-app-scrolling-collapse-issue-a-handy-trick-1abe
  #root {
    overflow-y: scroll;
    height: calc(100vh + 1px);
  }

  .pageTop {
    margin-top: 0;
  }

  .actionsList .actionsListButtons {
    bottom: 90px;
    width: calc(100% - 3rem);
  }

  .actionsList button {
    margin-left: 1rem;
  }
}

body.virtual-keyboard-attached {
  .DialogContentWrapper,
  .SwipeableDrawerContentWrapper {
    .NodeContent {
        .FormSubmitButton {
          position: relative;
          top: auto;
          bottom: auto;
          left: auto;
          right: auto;
          padding: 0;
          margin: 0
        }
      }
  }

  .FormContainer .submitNotice {
    margin-bottom: 1rem;
  }

  .SearchWrap .searchResultItemWrap {
    height: calc(100vh - 410px)
  }
}
