.ChatsTemplate {
    @media (max-width: 599px) {
        .coverWrap {
            margin: 0;
        }

        .title {
            margin-bottom: 1.5rem;
            padding-left: 1.5rem;
        }

        .items {
            margin-bottom: 2rem;
            padding-left: 2.5rem;
        }

        .MuiGrid-item {
            padding-top: 0!important;
            padding-bottom: 0!important;
            margin-bottom: 1rem!important;
        }

        .chatName svg {
            display: none;
        }

        .Chat {
            display: flex;
            align-items: center;

            .photoWrap {
                max-width: 40px;
                margin-right: .5rem;
                margin-bottom: 0;

                .thumbnail {
                    border-radius: 5px;
                }
            }

            .subtitle.notPublishedWrap {
                display: flex;
            }
        }

        .actions {
            width: 240px;
            top: 42px;

            .ActionButton {
                width: 25%;
                padding-bottom: 25%;
            }
        }

        .childrensList  {
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ccc;
            margin-top: 2rem;
            margin-bottom: 2rem;
        }
    }

    .childrensList  {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .chatName {
        display: flex;
        align-items: flex-start;

        svg {
            margin-right: .5rem;
        }
    }

    .LinksTileWithCategories .subtitle {
        padding-left: 0;
        margin-left: .2rem;
    }
}
