.FormContainer {
    .submitNotice {
        display: flex;
        background: rgba(3, 162, 3, 0.15);
        color: #306f26;
        padding: 1rem;
        border-radius: 15px;
        // margin-bottom: 4rem;

        svg {
            margin-right: .5rem;
        }
    }
}

.FormContainerConfirm {
    margin-bottom: 4rem;
    padding: 0 1rem;

    .FormValues {
        .labelWrap  {
            display: block;
        }

        .labelValue {
            flex-direction: column;
        }
    }

    .details {
        padding: 1rem;
        border: 1px solid #ccc;
        border-radius: 10px;
        margin-top: 2rem;
    }
}

.MuiFormControl-root .MuiInputBase-input.Mui-disabled {
    -webkit-text-fill-color: #aaa
}

.inputEmulation {
    position: relative;
    background-color: #fff;
    border: 1px solid #a2a2a2;
    border-radius: 20px;
    padding: 16.5px 14px;

    &.disabled {
        color: #aaa;
        border-color: #eaeaea;
    }

    &.error {
        color: rgb(211, 47, 47);
        border-color: rgb(211, 47, 47);
    }

    .inputLabel {
        position: absolute;
        color: rgb(119, 119, 119);
        font-size: 12px;
        font-weight: 400;
        top: -10px;
        background-color: #fff;
        padding-left: 2px;
        padding-right: 2px;
    }
}

.MuiFormControl-root.emulateEnabled {
    .MuiFormLabel-root.Mui-disabled {
        color: #111;
    }

    .MuiOutlinedInput-root.Mui-disabled {
        background-color: #fff;
        border: 1px solid #a2a2a2;
    }

    &.MuiFormControl-root .MuiFormLabel-filled.Mui-disabled {
        background: #fff;
    }

    &.error {
        .MuiOutlinedInput-root.Mui-disabled {
            border-color: rgb(211, 47, 47); 
        }
    }
}

.MuiAutocomplete-popper {
    border-radius: 20px;
}

.MuiPaper-root.MuiPaper-rounded {
    border-radius: 20px;

    .MuiAutocomplete-option.Mui-hover,
    .MuiAutocomplete-option.Mui-focused.Mui-focusVisible {
        background: #f4f4f4!important;
    }
}

////////////////////////////////////////////////////////

.fieldError {
    color: #d32f2f;
    font-size: 0.75rem;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
}

.fieldLabel {
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    // color: rgba(0, 0, 0, 0.6);
    padding-bottom: 0.4rem;

    font-size: 12px;
    margin-left: 11px;
    /* margin-bottom: -16px; */
    background: #fff;
    position: relative;
    display: inline;
    z-index: 1;
    top: 9px;
    padding-left: 3px;
    padding-right: 3px;
}

.fieldNotes {
    color: rgba(0, 0, 0, 0.5);
    font-size: .8rem;
    margin-bottom: 0;
    padding: 0 .5rem;

    &.fieldNotesRegexpError {
        color: rgb(211, 47, 47);
        font-weight: 500;
    }

    p {
        margin-top: .2rem;
        margin-bottom: 0;
    }
}

.noComponent {
    margin-left: 16px;
    padding: 16.5px 14px;
}

// hide mobile MUI DatePicker
.MuiPickersLayout-root {
    .MuiPickersToolbar-root,
    .MuiDialogActions-root {
        display: none;
    }
}

.FormFieldsGroup {
    margin-top: -1.2rem;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 1rem;
    padding-top: 2rem;
}

.FormFieldsGroupLabel {
    position: relative;
    z-index: 1;
    margin-left: .5rem!important;
    display: inline-block!important;
    width: auto!important;
    background: #fff;
    padding: 0 .5rem;
}

.DatePicker {

    &.error {
        .MuiFormLabel-root {
            color: rgb(211, 47, 47);
        }

        .MuiInputBase-root {
            border-color: rgb(211, 47, 47);
        }
    }

    .Mui-error {
        color: rgb(211, 47, 47)
    }
}

.systemFields {
    padding-bottom: 1rem;
    font-size: 1rem;
    font-weight: 400;
    padding-top: 1rem;
    margin-bottom: 50px;

    &.systemFieldsOpen {
        margin-bottom: 0;
    }

    @media (min-width: 600px) {
        margin-bottom: 0;
    }
}

.fieldExample {
    font-size: .8rem;
    font-style: italic;
    padding-left: 1rem;
    padding-top: .5rem;
    color: #999;
}

.controlWrapper {
    position: relative;
}

.controlWrapperDisabled {
    cursor: not-allowed;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
}