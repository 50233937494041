.LibraryListTemplate {
    .bookTitle {
        font-size: 1.1rem;
        line-height: 1.2rem;
    }

    .bookAuthors {
        color: #777;
        font-size: .8rem;
    }

    .nodesWidget {
        margin-top: 1rem;
    }
}
