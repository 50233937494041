.NodeTableTemplate {
    .TableWrap {
        margin-bottom: 3rem;
    }
}

.NodeTableTemplate {
    .TableWrap.recommendations {
        .Details .labelWrap {
            display: block;

            .label, .labelValue {
                display: inline;
            }
        }

        .Details .labelWrap.description {
            display: block;

            .label {
                display: none;
            }
        }

        .td_thumbnail {
            width: 30px;
            min-width: 30px;
            max-width: 30px;

            .tableImages {
                max-width: 30px;
                border-radius: 5px;
            }
        }

        @media (max-width: 599px) {
            .td_thumbnail,
            .td_title {
                padding-bottom: 0;
                font-size: 1.4rem;
                font-weight: 300;
            }

            .Details {
                padding-left: 0rem!important;
            }
        }

        @media (min-width: 600px) {
            .Details {
                margin-top: -1rem;

                .labelWrap {
                    margin-bottom: .5rem;
                }
            }
        }

        tr td {
            background: transparent;
        }

        tr.item td {
            border-top: 1px solid #eee;
        }
        tr.item:first-child {
            border-top: 0;
        }

    }
}
