
.pageTopTitle {
    display: flex;
    align-items: start;
    justify-content: space-between;
    line-height: 2rem;

    position: relative;
    z-index: 1;

    &.hasFilters {
        .pageTopTitleText {
            padding-top: 2px;
            @media (max-width: 599px) {
                padding-top: 4px;
            }
        }
    }

    .filterButtons {
        display: flex;

        align-items: start;

        .FilterField,
        .MuiButton-root,
        .MuiToggleButtonGroup-root {
            margin-left: .5rem;
            // border-color: #ddd;
        }

        .MuiButton-root {
            // border-radius: 50%;
            width: 42px;
            height: 42px;
        }

        .FilterField {
            width: 180px;
            display: flex;
        }

        .CheckboxField {
            white-space: nowrap;
        }

        .searchButton {
            &.hidden {
                opacity: 0;
            }
        }

        .MuiChip-deleteIcon {
            width: 14px;
            height: 14px;
        }

        .Mui-disabled {
            opacity: 0.8;
            color: #ddd;
            border-color: #ccc;
            cursor: not-allowed;
        }
    }

    .pageTopTitleText {
        display: flex;
        align-items: start;
        width: 100%;
    }

    .goBack.MuiSvgIcon-root {
        margin-top: 1px;
        margin-left: -.7rem;
        font-size: 2.2rem;
        opacity: .6;
        cursor: pointer;

        &:hover {
            opacity: .3;
        }
    }
}

.NodeMainTitle {
    position: relative;
}
