.HistoryFormAdminPage {
    .TableWrap {
        margin-bottom: 2rem;

        .labelWrap.data_text {
            display: block;

            .DetailsBlockWrap {
                width: 100%;
            }
        }

        .Details {
            .data_text {
                .label {
                    display: none;
                }
            }

            .DetailsBlockWrap {
                margin-top: 0;
            }
        }

        @media (max-width: 599px) {
            table.Table tr.item td {
                margin-bottom: 20px;
                display: block;
                box-sizing: border-box;
            }

            .Details .data_text {
                margin-top: 0!important;
            }

            .detailsTD {
                vertical-align: top!important;
            }

            .action {
                margin-top: .5rem;
                // position: relative;
                // height: 50px;

                // .MuiButton-root {
                //     width: calc(100% + 60px);

                //     position: absolute;
                //     left: 0;
                //     top: 0;
                // }
            }
        }
    }
    
//     .FiltersLine {
//         margin-bottom: 1rem;
//     }

//     .labelWrap.node {
//         align-items: start;

//         .labelValue {
//             padding-top: .2rem;
//         }
//     }

//     .RestoreAction {
//         margin-bottom: 2rem;
//     }

//     .labelWrap.patch {
//         align-items: start;
//         .label {
//         }
//         .labelValue {
//             padding-top: .2rem;
//         }
//     }
}

.HistoryFormAdminPageDetails {
    padding: 1rem;

    .labelWrap {
        display: block;

        .label {
            display: block;
            margin-bottom: .5rem;
        }

        .labelValue {
            gap: .5rem;
        }
    }
}
