.TileSections {
    padding-bottom: 1rem;

    .titleLink {
        display: block;
        // height: 100%;
        text-align: center;
        margin: 8px;
        // margin: 0.2rem 0.1rem;
        // background: #c1edff;
        // background-position: 50% 0 !important;
        // background-size: 100% auto !important;
        // background: #78c8ff;
        // 7ec9ff
        // 72c4ff
        // background: #8ad1ff;
        // background: rgb(52,106,156);
        // background: linear-gradient(77deg, rgba(52,106,156,1) 0%, rgba(105,183,255,1) 60%, rgba(0,212,255,1) 100%);
        //   linear-gradient(301deg, rgb(71 172 218) 0%, rgb(110 185 255) 60%, rgb(0, 212, 255) 100%)
    }

    .notPublished {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 26px;
        font-size: 34px;

        @media (min-width: 600px) {
            top: 42px;
        }
    }

    .titleText {
        display: block;
        text-align: center;
        text-transform: uppercase;
        padding-top: 8px;
        font-size: 10px;
        // color: #fff;
        color: #1a3855;
        font-weight: 500;

        @media (min-width: 600px) {
            font-size: 16px;
            font-weight: 400;
            padding-top: 14px;
            padding-bottom: 14px;
        }
    }

    .photoWrap {
        display: none;
    }

    .title {
        display: block;
        text-align: center;

        border-radius: 15px;
        box-shadow: 0 0 20px #e0e0e0;

        padding: 13px 5px;

        min-height: 92px;
        display: inline-flex;
        text-align: center;
        justify-content: center;
        flex-direction: column;

        margin: 0;

        @media (min-width: 600px) {
            min-height: 150px;
        }

        .material-icons,
        .icon {
            display: block;
            text-align: center;
            font-size: 40px;
            // position: absolute;
            // left: 0;
            // top: -150px;
            padding-top: 2px;

            text-shadow: 0 0 30px #fff;

            @media (min-width: 600px) {
                font-size: 70px;
            }
        }

        .icon {
            width: 100%;
        }

        .material-icons {
            margin: 0 auto;
        }
    }

    .thumbnail {
        display: none;
    }

    .LinksTileItemSkeleton {
        .title {
            display: none;
        }

        .LinksTileImageWrap {
            margin: 5px;
        }

        .MuiSkeleton-root {
            height: 100px!important;

            @media (min-width: 600px) {
                height: 200px!important;
            }
        }
    }
}

.TileSections {
    .material-icons {
        background: #333;
        color: #fff;
        border-radius: 50%;
        padding: 10px!important;
        font-size: 24px!important;
        text-shadow: none!important;
    }
}
