.ImagesList {
    display: flex;
    gap: 10px;

    .ImagesListItem {
        font-size: 0;
        // overflow: hidden;
        border-radius: 15px;
    }
}
