.MessengerField {
    position: relative;

    .MuiGrid-root.MuiGrid-item {
        padding-top: 12px!important;

        &.noMargin {
            padding-top: 0!important;
        }
    }

    .PhoneField {
        .MuiGrid-root.MuiGrid-item {
            @media (min-width: 600px) {
                padding-top: .5rem!important;
            }
        }

        .MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root {
            @media (max-width: 599px) {
                padding-right: 2rem!important;
            }
        }
    }

    .MuiGrid-root.MuiGrid-item.ContactLabel {
        position: absolute;
        left: 16px;
        top: -14px;
        background: #fff;
        margin: 0!important;
        padding: 0!important;
    }

    .ContactControlWrap {
        display: flex;
        gap: .5rem;

        @media (max-width: 599px) {
            flex-direction: column;
        }

        .ContactControlUsername,
        .ContactControlPhone {
            width: 100%;
        }

        @media (max-width: 599px) {
            .ContactControlPhone {
                margin-top: .5rem;
            }
        }
    }

    .addSign {
        margin-top: 1rem;
        font-size: 0.9rem;
    }

    .fieldNotes {
        margin-top: .5rem;

        p {
            margin-bottom: 0;
        }
    }

}

.messengerOption {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.CountriesListItem {
    text-align: center;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: .4rem .5rem;

    .code {
        cursor: pointer;
        font-size: 14px;

        @media (min-width: 600px) {
            font-size: 16px;
        }
    }
}

.PhoneField {
    position: relative;
    display: flex;

    .code {
        min-width: 120px;
        margin-right: .5rem;
    }

    .text {
        width: 100%;
    }

    .MuiAutocomplete-input {
        cursor: pointer;
    }

    .MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root {
        padding-left: 1.8rem;
        padding-right: 2rem !important;
    }

    .flags {
        position: absolute;
        left: 12px;
        top: 18px;
        z-index: 1;
        width: 20px;
    }
}
