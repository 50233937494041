.ValidateErrorPage {
    .messageWrap {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        min-height: 200px;
        padding: 2rem 0;
        gap: 2rem;
    }

    .message {
        white-space: break-spaces;
    }

    .messageError {
        padding: 0 3rem;
        color: #800;
    }

    .message,
    .actionsBox {
        max-width: 400px;
        margin: 0 auto;
    }

    .actionsBox {
        display: block;
        width: 100%;

        .pseudoButton {
            text-align: center;
        }
    }
}
