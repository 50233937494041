.LocationWidget {
    .LocationWidgetMap {
        border-radius: 15px;
        overflow: hidden;
    
        margin-bottom: .5rem;
    }

    .Link {
        padding-left: 0!important;
        margin-left: 0!important;
        margin-top: 1rem;
        font-size: 14px;
    }
}
