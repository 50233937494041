.HistoryShopOrderAdminPage {
    .FormValues {
        border: 1px solid #ddd;
        border-radius: 15px;
        padding: 1rem;

        .labelWrap {
            padding-left: .5rem;

            @media (max-width: 559px) {
                display: block;
            }  
        }
    }

    .StatusUpdate {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin: 1rem;
        margin-top: 2rem;

        .status {
            padding: 0 1rem;
        }

        .statusWrap {
            display: flex;
            gap: 1em;

            @media (max-width: 599px) {
                flex-direction: column;
            }
        }
    }
}